@variants responsive {
  .img-pq-logo-lg {
    background: url("img/pqLogoLg.png");
    background-size: 100%;
    height: calc(110px / 2);
    width: calc(318px / 2);
  }

  .img-pq-logo-sm {
    background: url("img/pqLogoSm.png");
    background-size: 100%;
    height: calc(57px / 2);
    width: calc(352px / 2);
  }

  .img-placeholder-1 {
    background: url("img/placeholder1.png");
    background-size: 100%;
    mix-blend-mode: multiply;
    height: calc(946px / 4.4);
    width: calc(746px/ 4.4);
  }

  .img-placeholder-1-sm {
    background: url("img/placeholder1.png");
    background-size: 100%;
    mix-blend-mode: multiply;
    height: calc(946px / 4);
    width: calc(746px/ 4);
  }
}

.img-assessment-instructions {
  background: url("img/pageImages/assessmentInstructions.png");
  background-size: 100%;
  height: calc(488px / 2);
  width: calc(540px / 2);
}

.img-sab-overview {
  background: url("img/pageImages/sabOverview.png");
  background-size: 100%;
  height: calc(518px / 2);
  width: calc(614px / 2);
}

.img-sab-results-intro {
  background: url("img/pageImages/sabResultsIntro.png");
  background-size: 100%;
  height: calc(757px / 2);
  width: calc(708px / 2);
}

.img-sab-results-more {
  background: url("img/pageImages/sabResultsMore.png");
  background-size: 100%;
  height: calc(488px / 2);
  width: calc(508px / 2);
}

.img-pq-overview {
  background: url("img/pageImages/pqOverview.png");
  background-size: 100%;
  height: calc(518px / 2);
  width: calc(630px / 2);
}

.img-pq-definition {
  background: url("img/pageImages/pqDefinition.png");
  background-size: 100%;
  height: calc(633px / 2);
  width: calc(386px / 2);
}

.img-pq-score {
  background: url("img/pageImages/pqScore.png");
  background-size: 100%;
  height: calc(580px / 2);
  width: calc(538px / 2);
}

.img-pq-meaning {
  background: url("img/pageImages/pqMeaning.png");
  background-size: 100%;
  height: calc(512px / 2);
  width: calc(612px / 2);
}

