@font-face {
  font-family: Matter;
  src: url('./fonts/Matter-SemiBold.woff2') format('woff2'),
  url('./fonts/Matter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Matter;
  src: url('./fonts/Matter-Regular.woff2') format('woff2'),
  url('./fonts/Matter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ITC Cheltenham';
  src: url('./fonts/ITC-Cheltenham-Book.woff2') format('woff2'),
  url('./fonts/ITC-Cheltenham-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@variants responsive {
  .heading-1 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    line-height: 80px;
  }

  .heading-2 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 72px;
  }

  .heading-3 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
  }

  .heading-4 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  .heading-5 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }

  .heading-6 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }

  .heading-7 {
    font-family: Matter, 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .small-type {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .small-type-medium {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .xsmall-type {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14.4px;
  }

  .xsmall-type-medium {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15.32px;
  }

  .body-lg {
    font-family: 'ITC Cheltenham', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
  }

  .body {
    font-family: 'ITC Cheltenham', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }

  .body-sm {
    font-family: 'ITC Cheltenham', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }

  .body-lg {
    font-family: 'ITC Cheltenham', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
  }

  .body-caption {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .body-footer {
    font-family: Matter, 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14.4px;
  }

  .text-error {
    color: #D51030
  }
}

p {
  margin: 24px 0;
}
